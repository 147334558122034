@import "../../../mixins.scss";

$dark: #131325;

.card {
  cursor: pointer;
  user-select: none;
  border-radius: 6px;

  perspective: 600px;
  transition: all 0.1s;
  flex-direction: column;
  height: 220px;

  justify-content: center;

  .overlay {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(1px);
    z-index: 2;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
  }

  &.sold-out {
    position: relative;

    .overlay {
      pointer-events: all;
      opacity: 1;
    }
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
    height: 110px;
    justify-content: initial;
    align-items: flex-start;
  }

  & .bottom {
    overflow: hidden;
    // width: 200px;
    font-size: 12px;
    color: #777;
    font-weight: normal;
  }
  &.open {
    padding: 20px 0px;

    @include for-tablet-landscape-up {
      padding: 30px;
    }
    height: auto;
    & .bottom {
      margin-top: 10px;
      height: 100%;
      overflow: visible;
    }
    & .book:not(.mobile) {
      transform: rotateY(50deg);
      box-shadow: -10px 10px 10px 2px rgba(0, 0, 0, 0.2), -2px 0px 0px 0px #888;
      transition: all 0.5s;
      transition-delay: 0.05s;
    }
    & .info {
      transform: translate(0, -10px);
    }
    & .prices {
      padding: 15px 20px;
      border-radius: 4px;
      align-self: flex-start;
      padding-bottom: 0;
    }
  }

  background-color: lighten($dark, 8%);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.6);

  & .book {
    transition: all 0.5s;
    width: 120px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    display: none;

    @include for-tablet-landscape-up {
      display: initial;
    }

    &.mobile {
      display: initial;
      width: 80px;
      height: 120px;
      @include for-tablet-landscape-up {
        display: none;
      }
    }
  }
  & .info {
    transition: all 0.2s;
    flex-grow: 2;
    padding: 10px 30px;
    font-family: "Montserrat";
    font-weight: bold;
    overflow: hidden;
    word-wrap: break-word;

    & .title {
      font-size: 1em;
      color: #fff;
      letter-spacing: 1px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    & .subtitle {
      font-size: 0.8em;
      color: #fff;
      letter-spacing: 1px;
      opacity: 0.6;
      font-weight: thin;
    }
    & .author {
      font-size: 12px;
      font-weight: normal;
      color: #888;
    }
  }

  .label {
    opacity: 0.5;
    font-size: 12px;
  }

  & .group {
    margin: auto;
    margin-top: 12px;
    background-color: lighten($dark, 5%);
    padding: 20px;
    min-width: 200px;
    @include for-tablet-landscape-up {
      margin: 0;
      margin-left: auto;
      padding: 8px;
    }

    h5 {
      color: white;
      margin: 0;
    }
  }
  & .prices {
    transition: all 0.1s;
    font-family: "Montserrat";
    color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    .separator {
      font-size: 32px;
      padding-right: 6px;
      padding-left: 8px;
      opacity: 0.5;
    }

    .price {
      display: flex;
      justify-content: center;
      flex-direction: column;

      align-items: center;
      .amount {
        font-weight: bold;
        font-size: 24px;
      }
    }
  }

  .amount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap: 12px;

    .ticket-counter {
      flex-grow: 2;
      font-weight: bold;
      color: white;
      font-size: 16px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .available {
        font-size: 12px;
        opacity: 0.5;
      }

      span {
        text-align: center;
        justify-content: center;
      }
    }
  }

  $button-color: white;
  $button-text-color: white;
  $button-size: 32px;
  button.btn {
    flex-shrink: 0;
    background: $button-color;
    border-radius: 6px;
    padding: 6px;

    border: none;
    outline: none;
    color: $button-text-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.fixed-size {
      width: $button-size;
      height: $button-size;
      aspect-ratio: 1/1;
    }

    &.dark {
      color: black;
    }

    &.plus {
      background: green;
    }

    &.minus {
      background: red;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}
