@import "../../../mixins.scss";

.checkout {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px;
  background: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
  left: 0;
  z-index: 2;

  // @include for-tablet-landscape-up {
  //   position: relative;
  //   background: none;
  //   width: auto;
  //   padding: 0;
  //   display: initial;
  //   justify-content: flex-end !important;
  // }
}
