#root {
  padding-bottom: 160px;
  min-height: 65vh;
  button {
    &.MuiButton-containedPrimary {
      color: black !important;
    }
  }

  h5 {
    word-break: break-word;
  }

  hr {
    background-color: transparent;
  }

  .MuiDialog-paper {
    padding: 12px;
  }

  .MuiDialogActions-root {
    gap: 12px;
  }
}
