@import "../../../mixins.scss";

.checkout {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 32px !important;
  background: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center !important;
  left: 0;
  z-index: 2;

  span {
    color: white;
    @include for-tablet-landscape-up {
      color: initial;
    }
  }

  @include for-tablet-landscape-up {
    position: relative;
    background: none;
    width: auto;
    padding: 0;
    display: initial;
    justify-content: flex-end !important;
    padding: 0 !important;
    padding-top: 16px !important;
  }
}

.inner-stack {
  @include for-tablet-landscape-up {
    align-items: flex-end !important;
    flex-direction: column-reverse;
  }
}

.stepper-wrapper {
  @include for-tablet-landscape-up {
    padding-bottom: 16px !important;
  }
}
