@font-face {
  font-family: Recoleta;
  src: url("./assets/fonts/Azonix.otf") format("truetype");
}

@font-face {
  font-family: BRAMS;
  src: url("./assets/fonts/Azonix.otf") format("truetype");
}

.zweitakt-ticket {
  width: 4.1in;
  height: 5.8in;
  position: relative;

  div,
  dl,
  dt,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  code,
  blockquote {
    margin: 0;
    padding: 0;
    border-width: 0;
  }

  @media print {
    -webkit-print-color-adjust: exact;
  }

  .border-container {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .border {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      border: solid 3px orange;
    }

    z-index: 20;
  }

  .logo {
    position: absolute;
    height: 42px;
    top: 26px;
    right: 26px;
    z-index: 22;
  }

  .content-1 {
    position: absolute;
    width: 260px;
    top: 16px;
    left: calc(50% - 130px);
    z-index: 23;
  }

  .content-2 {
    position: absolute;
    width: 200px;
    top: 48px;
    left: calc(50% - 87px);
    z-index: 23;
  }

  .content-3 {
    position: absolute;
    width: 180px;
    top: 48px;
    left: calc(50% - 82px);
    z-index: 23;
  }

  .ellipsis {
    position: absolute;
    width: 60px;
    z-index: 30;
    left: calc(50% - 30px);

    &.top {
      top: -6px;
    }

    &.bottom {
      bottom: -6px;
    }
  }

  .overlay-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .overlay-image-bg {
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    position: absolute;
    -webkit-print-color-adjust: exact;
  }

  .date {
    text-shadow: -0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white;

    position: absolute;
    left: 0;
    top: 34px;
    z-index: 12;
    width: 100%;
    color: #ec6128;
    font-family: Recoleta, serif;
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
    text-align: center;
    -webkit-print-color-adjust: exact;
  }

  .ticket-name {
    position: absolute;
    left: 12px;
    right: 12px;
    top: 290px;
    z-index: 12;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;

    h2 {
      margin: 0;
      // color: #ffffff;
      font-family: BRAMS, serif;
      font-size: 28px;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1.2;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      orphans: 1;
      page-break-after: auto;
      page-break-before: auto;
      text-align: center;
      text-decoration: none;
      text-indent: 0;
      text-transform: none;
      widows: 1;
      padding: 2px 0px;
      white-space: nowrap;
    }

    p {
      font-family: Recoleta, serif;
      // color: black;
      // background-color: white;
      width: 100%;
      backdrop-filter: blur(12px);
      text-align: center;
      -webkit-print-color-adjust: exact;
    }
  }

  .dicsounted {
    font-size: 10px;
  }

  .data {
    padding: 0 40px;
    position: absolute;

    z-index: 12;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-around;
    font-weight: bold;

    .rows {
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      font-size: 11.5px;
      line-height: 15px;

      .row {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .qr-code {
      width: 2.5cm;
    }
  }

  .notice {
    text-align: center;
    padding: 0 70px;
    position: absolute;
    bottom: 50px;
    z-index: 12;
    display: flex;
    width: 100%;
    color: black;
    font-size: 11px;
    line-height: 13px;
  }

  div.Basic-Graphics-Frame {
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
  }
  div.Basic-Text-Frame {
    border-style: solid;
  }
  p.Copytext {
    color: #3f2638;
    font-family: "Recoleta Medium", serif;
    font-size: 7px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1.429;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.Datum {
    color: #f26723;
    font-family: "Recoleta Medium", serif;
    font-size: 23px;
    font-style: normal;
    font-variant: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.Headline {
    color: #ffffff;
    font-family: BRAMS, serif;
    font-size: 45px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    orphans: 1;
    page-break-after: auto;
    page-break-before: auto;
    text-align: center;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    widows: 1;
  }
  p.ParaOverride-1 {
    text-align: center;
  }
  #_idContainer000 {
    display: inline-block;
    height: 177px;
    position: relative;
    width: 298px;
  }
  #_idContainer001 {
    display: inline-block;
    height: 420px;
    width: 298px;
  }
  #_idContainer002 {
    display: inline-block;
    height: 49px;
    position: relative;
    width: 272px;
  }
  #_idContainer005 {
    display: inline-block;
    height: 57px;
    width: 56px;
  }
  img._idGenObjectAttribute-1 {
    height: 100%;
    min-width: 100%;
    width: 100%;
  }
  div._idGenObjectStyleOverride-1 {
    background-color: #8781bd;
    border-width: 0px;
  }
  div._idGenObjectStyleOverride-2 {
    border-width: 0px;
  }
  div._idGenObjectLayout-1 {
    text-align: center;
  }
}
